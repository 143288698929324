import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { getUserTabins } from "../features/tabin/tabinSlice";
import { Mixpanel } from "../app/mixpanel-instance";
const TAG = "components.Sidebar ";

function Sidebar({ tabins, onClickHandler }) {
	console.log(TAG);

	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);

	const [currentPage, setCurrentPage] = useState(0);
	const tabinsPerPage = 8; // Number of tabins displayed per page

	const totalPages = Math.ceil(tabins.length / tabinsPerPage);

	const startIndex = currentPage * tabinsPerPage;
	const endIndex = startIndex + tabinsPerPage;
	const currentTabins = tabins.slice(startIndex, endIndex);

	const handleNext = () => {
		if (currentPage < totalPages - 1) {
			setCurrentPage((prev) => prev + 1);
		}
	};

	const handlePrevious = () => {
		if (currentPage > 0) {
			setCurrentPage((prev) => prev - 1);
		}
	};

	const refreshBtnClickHandler = async () => {
		Mixpanel.track("refresh btn clicked tabcase");
		localStorage.removeItem("bins");
		console.log(TAG + ".btnclickhandler getting tabins from db");
		await dispatch(getUserTabins(user.google_id));
		window.location.reload();
	};

	return (
		<div className='block w-[30%] h-auto xs:pt-[80px] xs:pl-[5px] sm:pt-[90px] sm:pl-[20px] md:pl-[40px] lg:pl-[80px] xl:pl-[140px]'>
			<div className='flex justify-center items-center h-[65px] w-[94%] bg-[#FCF6DA] border-solid border-2 border-black'>
				<div className='flex w-[80%] justify-center font-semibold xs:text-xl sm:text-3xl'>
					Tabins
				</div>

				<div
					className='flex w-[23%] justify-center font-semibold xs:text-xl sm:text-3xl opacity-60 webkitfilter hover:opacity-100'
					type='button'
					onClick={refreshBtnClickHandler}
				>
					<img
						src='refresh.png'
						alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
					></img>
				</div>
			</div>
			<div className='flex w-[94%] h-[auto] bg-[#AAF0DA] p-2 justify-center items-center border-solid border-[1px] border-black'>
				<ul className='p-3 m-1 w-full'>
					{currentTabins.map((bin) => (
						<div className='flex justify-center items-center' key={bin._id}>
							<li
								className='flex h-[auto] bg-[#ffffff] justify-center items-center cursor-pointer changeonhover xs:p-1 xs:rounded-none xs:w-[auto] xs:m-1 sm:p-3 sm:w-[150px] sm:m-2 sm:rounded-full md:w-[225px] lg:w-[270px] xl:w-[340px]'
								id={bin._id}
								onClick={() => onClickHandler(bin._id)}
							>
								<span className='flex w-[100%] justify-center items-center xs:p-1 xs:text-sm md:text-lg'>
									{bin.title.length > 20
										? bin.title.slice(0, 18) + "...."
										: bin.title}
								</span>
							</li>
						</div>
					))}

					{/* Pagination Buttons */}
					<div className='flex justify-between mt-4'>
						{currentPage > 0 && (
							<button
								className='btn p-2 rounded text-black bg-gray-200 hover:bg-gray-300'
								onClick={handlePrevious}
							>
								Previous
							</button>
						)}
						{currentPage < totalPages - 1 && (
							<button
								className='btn p-2 rounded text-black bg-gray-200 hover:bg-gray-300'
								onClick={handleNext}
							>
								Next
							</button>
						)}
					</div>
				</ul>
			</div>
		</div>
	);
}

Sidebar.propTypes = {
	tabins: PropTypes.array.isRequired,
	onClickHandler: PropTypes.func.isRequired,
};

export default Sidebar;
