import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import moment from "moment";

import { RiDeleteBinLine } from "react-icons/ri";
import { IconContext } from "react-icons";

const EditEventModal = ({ isOpen, onClose, onClickDelete, editEvent }) => {
	const { title, start, end, _id, repeatDaily } = editEvent;

	// Ensure the checkbox state is always defined (default to `false` if `repeatDaily` is undefined
	const [repeatDailyIsChecked, setRepeatDailyIsChecked] = useState(
		repeatDaily || false
	);

	// Update the checkbox state when `repeatDaily` changes
	useEffect(() => {
		setRepeatDailyIsChecked(repeatDaily);
	}, [repeatDaily]);

	// Handle checkbox change
	const handleRepeatDailyCheckboxChange = () => {
		const updatedEvent = {
			extendedProps: { _id },
			repeatDaily: !repeatDailyIsChecked,
		};
		setRepeatDailyIsChecked(!repeatDailyIsChecked);
		updateEvent(updatedEvent);
	};

	// Function to update event in the database
	async function updateEvent(event) {
		try {
			await axios.put("/api/calendar/update-event", event);
			console.log("Event updated successfully");
		} catch (err) {
			console.error("Error updating event:", err);
		}
	}

	// Custom modal styles
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
		},
	};

	// Submit handler
	const onSubmit = (event) => {
		event.preventDefault();
		onClose();
	};

	// Delete button click handler
	const onDeleteBtnClickHandler = () => {
		onClickDelete({ id: _id });
		onClose();
	};

	return (
		<div className='flex relative'>
			<Modal
				style={customStyles}
				ariaHideApp={false}
				closeTimeoutMS={0}
				isOpen={isOpen}
				onRequestClose={onClose}
			>
				<div className='flex-row'>
					<div className='flex pb-2'>
						<div className='flex h-[30px] w-[100%] font-bold text-xl justify-center items-center border-b-2'>
							{title}
						</div>
					</div>

					<form onSubmit={onSubmit}>
						<div className='flex border-b-2'>
							<div className='flex'>
								<label className='p-1'>{moment(start).format("dddd")}</label>
								<label className='p-1'>{moment(start).format("MMMM-DD")}</label>
								<label className='p-1'>{moment(start).format("h:mm")}</label>
							</div>
							<div className='flex'>
								<label className='p-1'>{moment(end).format("h:mm")}</label>
							</div>
						</div>
						<div className='flex justify-center items-center'>
							<label className='flex items-center'>
								<input
									type='checkbox'
									checked={repeatDailyIsChecked}
									onChange={handleRepeatDailyCheckboxChange}
									className='mr-2'
								/>
								<span>Repeat Daily</span>
							</label>
							<IconContext.Provider value={{ color: "red", className: "" }}>
								<button onClick={onDeleteBtnClickHandler} className=''>
									<RiDeleteBinLine className='flex h-[30px] w-[40px] m-2 opacity-50 hover:opacity-100' />
								</button>
							</IconContext.Provider>
						</div>
					</form>
				</div>
			</Modal>
		</div>
	);
};

export default EditEventModal;
