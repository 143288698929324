import React from "react";
import { FcCheckmark } from "react-icons/fc";
import { FaClock } from "react-icons/fa";
import { useEffect } from "react";
import GetStartedButton from "../components/GetStartedButton";
import ReactGA from "react-ga4";
import { Mixpanel } from "../app/mixpanel-instance";
import { Helmet } from "react-helmet";

const BASIC_PRICE = process.env.REACT_APP_BASIC_PRICE;
const PRO_PRICE = process.env.REACT_APP_PRO_PRICE;
const ENTERPRISE_PRICE = process.env.REACT_APP_ENTERPRISE_PRICE;

function Pricing() {
	useEffect(() => {
		Mixpanel.track_pageview();
		ReactGA.send({
			hitType: "pageview",
			page: "/pricing",
			title: "pricing",
		});
	}, []);
	return (
		<div className='px-4 mb-[200px]'>
			<Helmet>
				<title>
					Tabin | Pricing - Context Switching Made Easy To Increase Productivity
				</title>
				<link rel='canonical' href='https://tabin.io/pricing' />
			</Helmet>
			<div className='grid justify-center  items-center mt-[100px] mx-[10px] sm:mx-[30px] md:mx-[100px] lg:mx-[150px] xl:mx-[350px] xxl:mx-[650px]'>
				<h1 className='text-6xl font-semibold'>
					Get more productivity out of your day
				</h1>

				<p className='pl-6 mt-4'>
					Enjoy free trail version of Tabin and streamline your workflow by
					eliminating the hassle of context switching. Create, Share, Schedule
					Tabins as you like.
					{/* Start with few Tabins on us, and then upgrade to our paid plan (coming
					soon) as it fits your needs. */}
				</p>
			</div>
			<section className='grid sm:flex h-[auto] mt-[100px] bg-white justify-center  items-center mx-[30px] sm:mx-[30px] md:mx-[100px] lg:mx-[150px] xl:mx-[350px] xxl:mx-[650px]'>
				<div className='flex-1 bg-gray-200 mr-2 rounded-xl pt-[18px] pb-10 mb-8 px-1 max-w-sm md:max-w-md lg:max-w-lg'>
					<h1 className='flex justify-center items-center mt-4 text-6xl font-bold'>
						Free Trial
					</h1>
					<p className='flex justify-center items-center mt-6 text-md text-gray-700 mt-2'>
						Please enjoy tabin for limited trail period. It is free to use.
					</p>

					<h1 className='flex justify-center items-center mt-12 text-4xl font-bold'>
						{BASIC_PRICE}, No Payment
					</h1>

					{/* <button className='flex justify-center items-center  bg-white h-14 w-[95%] ml-2 mt-12 rounded-3xl opacity-60 hover:opacity-100'>
						Sign up
					</button> */}
					<p className='flex items-center mt-10 pb-2 text-xl'>Key Features</p>
					<div className='w-auto h-auto'>
						<div className='flex w-[90%] ml-8 mt-2'>
							<FcCheckmark />
							<p className='ml-4'>Create Tabins</p>
						</div>
						<div className='flex w-[90%] ml-8 mt-2'>
							<FcCheckmark />
							<p className='ml-4'>Share Tabin with others</p>
						</div>
						<div className='flex w-[90%] ml-8 mt-2'>
							<FcCheckmark />
							<p className='ml-4'>Schedule Tabins</p>
						</div>
						<div className='flex w-[90%] ml-8 mt-2'>
							<FaClock />
							<p className='ml-4'>Create Private Tabins (coming soon)</p>
						</div>
						<div className='flex w-[90%] ml-8 mt-2'>
							<FaClock />
							<p className='ml-4'>QR Code for a Tabin (coming soon)</p>
						</div>
						<div className='flex w-[90%] ml-8 mt-2'>
							<FaClock />
							<p className='ml-4'>Tabin Highlights (coming soon)</p>
						</div>
						<div className='flex w-[90%] ml-8 mt-2'>
							<FaClock />
							<p className='ml-4'>Tabin Conversations (coming soon)</p>
						</div>
						<div className='flex w-[90%] ml-8 mt-2'>
							<FaClock />
							<p className='ml-4'>Tabin Company Page (coming soon)</p>
						</div>
						<div className='flex w-[90%] ml-8 mt-2'>
							<FaClock />
							<p className='ml-4'>Tabin Notes AI (coming soon)</p>
						</div>
						<div className='flex justify-center items-center mt-6'>
							<i className='flex flex-nowrap w-[490px] h-[120px] justify-center items-center'>
								<GetStartedButton />
							</i>
						</div>
					</div>
				</div>

				{/* <div className='flex-1  bg-gray-300 mr-2 rounded-xl pt-[18px] pb-10 mb-8 px-1'>
					<h1 className='flex justify-center items-center mt-4 text-2xl font-bold'>
						Basic
					</h1>
					<p className='flex justify-center items-center mt-1 text-sm text-gray-700'>
						For users who would like to create, schedule, and share.
					</p>

					<h1 className='flex justify-center items-center mt-12 text-4xl font-bold'>
						{BASIC_PRICE}
					</h1>

					<button className='flex justify-center items-center  bg-white h-14 w-[95%] ml-2 mt-12 rounded-3xl opacity-60 hover:opacity-100'>
						Sign up
					</button>
					<p className='flex justify-center items-center mt-10 pb-2'>
						Key Features
					</p>
					<div className='flex ml-8 mt-2'>
						<FcCheckmark />
						<p className='ml-4'>Tabins are limited</p>
					</div>
					<div className='flex ml-8 mt-2'>
						<FcCheckmark />
						<p className='ml-4'>Share Tabins with others</p>
					</div>
					<div className='flex ml-8 mt-2'>
						<FcCheckmark />
						<p className='ml-4'>Tabins are public</p>
					</div>
				</div>
				<div className='flex-1 bg-lightgreen mr-2 rounded-xl pb-10 mb-8 border-4 border-lightyellow shadow-lg shadow-lightyellow'>
					<div className='flex w-[50%] justify-center items-center mt-[-18px] rounded-2xl border-2 ml-4 py-1 bg-lightyellow'>
						<p className='text-xs uppercase font-semibold md:text-sm'>
							most popular
						</p>
					</div>
					<h1 className='flex justify-center items-center mt-4 text-2xl font-bold'>
						Pro
					</h1>
					<p className='flex justify-center items-center mt-1 text-sm text-gray-700'>
						For small teams that wasnts efficiency in productity.
					</p>

					<div className='flex justify-center items-center mt-12'>
						<h1 className=' text-4xl font-bold'>${PRO_PRICE}</h1>
						<p className='mt-2  text-xl'>/month</p>
					</div>

					<button className='flex justify-center items-center  h-14 w-[95%] ml-2 mt-12 rounded-3xl opacity-80 bg-lightyellow hover:bg-[#fbff11] hover:opacity-100'>
						Sign up
					</button>
					<p className='flex justify-center items-center mt-10 pb-2 font-semibold'>
						Everything in Basic, and{" "}
					</p>
					<div className='flex ml-8 mt-2'>
						<FcCheckmark />
						<p className='ml-4'>Unlimited Tabins</p>
					</div>
					<div className='flex ml-8 mt-2'>
						<FcCheckmark />
						<p className='ml-4'>Schedule Tabins</p>
					</div>
					<div className='flex ml-8 mt-2'>
						<FcCheckmark />
						<p className='ml-4'>Create Private Tabins</p>
					</div>
					<div className='flex ml-8 mt-2'>
						<FaClock />
						<p className='ml-4'>QR Code for a Tabin</p>
					</div>
					<div className='flex ml-8 mt-2'>
						<FaClock />
						<p className='ml-4'>Tabin Highlights</p>
					</div>
					<div className='flex ml-8 mt-2'>
						<FaClock />
						<p className='ml-4'>Tabin Conversations</p>
					</div>
				</div>
				<div className='flex-1 bg-pink mr-2 rounded-xl pt-[18px] pb-10 mb-8 px-1'>
					<h1 className='flex justify-center items-center mt-4 text-2xl font-bold'>
						Enterprise
					</h1>
					<p className='flex justify-center items-center mt-1 text-sm text-gray-700'>
						Ideal for business that use Tabin to grow their productivity.
					</p>

					<h1 className='flex justify-center items-center mt-12 text-4xl font-bold'>
						{ENTERPRISE_PRICE}
					</h1>

					<button className='flex justify-center items-center bg-white h-14 w-[95%] ml-2 mt-12 rounded-3xl opacity-80 hover:opacity-100'>
						Sign up
					</button>
					<p className='flex justify-center items-center mt-10 pb-2 font-semibold '>
						Everything in Pro, and
					</p>
					<div className='flex ml-8 mt-2'>
						<FaClock />
						<p className='ml-4'>Customizable </p>
					</div>
					<div className='flex ml-8 mt-2'>
						<FaClock />
						<p className='ml-4'>Add Company Logo </p>
					</div>
					<div className='flex ml-8 mt-2'>
						<FaClock />
						<p className='ml-4'>Private Company Tabin page</p>
					</div>
				</div> */}
			</section>
		</div>
	);
}

export default Pricing;
